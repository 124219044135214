/*
  This is used instead of Tailwind's preflight to prevent
  overriding Antd button background colors.

  https://github.com/tailwindlabs/tailwindcss/issues/6602
*/
@import './tailwind-preflight.css';

@import '~tailwindcss/base';
@import '~tailwindcss/components';
@import '~tailwindcss/utilities';

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-tabs-top > .ant-tabs-nav::before {
  @apply border-none;
}

// this functionality hides the
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button,
input[type='number'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }

    .scrollbar-size {
      width: 5px;
      margin-right: 5px;
      border-radius: 5px;
    }

    .primary-scrollbar::-webkit-scrollbar {
      @apply scrollbar-size;
    }

    .primary-scrollbar::-webkit-scrollbar-track {
      @apply rounded bg-[rgba(0_0_0_0.2)];
    }

    .primary-scrollbar::-webkit-scrollbar-thumb {
      @apply bg-primary-4;
      border-radius: 5px;
    }

    .primary-scrollbar::-webkit-scrollbar-thumb:hover {
      @apply bg-primary-8;
    }

    .horizontal-thin-scrollbar {
      scrollbar-width: thin;
    }

    .horizontal-thin-scrollbar::-webkit-scrollbar {
      width: 2px;
      margin-right: 5px;
      border-radius: 5px;
    }

    .horizontal-thin-scrollbar::-webkit-scrollbar-track {
      @apply rounded bg-[rgba(0_0_0_0.9)];
    }

    .horizontal-thin-scrollbar::-webkit-scrollbar-thumb {
      @apply bg-primary-4;
      background-clip: padding-box;
      border-radius: 5px;
    }

    .horizontal-thin-scrollbar::-webkit-scrollbar-thumb:hover {
      @apply bg-primary-8;
    }

    .secondary-scrollbar::-webkit-scrollbar {
      @apply scrollbar-size;
    }

    .secondary-scrollbar::-webkit-scrollbar-track {
      @apply rounded bg-gray-200;
    }

    .secondary-scrollbar::-webkit-scrollbar-thumb {
      @apply scrollbar-size bg-gray-400;
      border-radius: 5px;
    }

    .secondary-scrollbar::-webkit-scrollbar-thumb:hover {
      @apply bg-gray-400;
    }
  }
}
